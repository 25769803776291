<template>
  <div>
    <!-- float button add -->
    <div class="btn-scroll-to-top">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-right
        variant="primary"
        size="lg"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="PlusIcon" size="16" />
      </b-button>
    </div>
    <b-alert v-height-fade show dismissible fade variant="primary">
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan cuti !</span>
      </div>
    </b-alert>
    <b-alert v-height-fade show dismissible fade class="mb-2" variant="warning">
      <div class="alert-body">
        <span>Klik <b-link href="#" class="alert-link" @click="voucherRemaining()">disini</b-link> untuk melihat sisa voucher cuti !</span>
      </div>
    </b-alert>
    <b-card title="Pencarian Data Cuti Karyawan">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="statusReff"
                  :disabled="isSpinner"
                  placeholder="Pilih Status"
                  label="statusName"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="month_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Bulan"
                  label="monthName"
                  :options="monthPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="year_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Tahun"
                  label="year"
                  :options="yearPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="searchLeaveEmployee()"
                >
                  <b-spinner
                    v-show="isSpinner"
                    class="ml-auto"
                    :hidden="!isSpinner"
                    small
                    type="grow"
                  />
                  <div :hidden="isSpinner">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </div>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  block
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- pricing free trial -->
    <div class="pricing-free-trial" v-if="isNull">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">Data cuti tidak ditemukan!</h3>
              <h5>Klik tombol + untuk melakukan pengajuan cuti.</h5>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <b-overlay
      :show="isOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row v-if="isTableShow">
        <b-col md="4" sm="12" v-for="leave in leaves" :key="leave.leave_code">
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title class="mb-1">{{ leave.leaves_code }}</b-card-title>
                <b-card-sub-title class="mb-0 font-italic">
                Dibuat pada {{ timeAgo(leave.created_at) }}
                </b-card-sub-title>
              </div>

              <b-dropdown
                variant="link"
                no-caret
                right
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item 
                href="#" 
                @click="onClickUpdate(leave)"
                :disabled="leave.sg_name !== 'PROPOSED'"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item 
                href="#" 
                @click="confirmText(leave)"
                :disabled="leave.sg_name !== 'PROPOSED'"
                > 
                  Batal 
                </b-dropdown-item>
              </b-dropdown>


            </b-card-header>

            <b-card-body>
              <b-card-text>
                Mengajukan cuti selama
                <strong>{{
                  (new Date(leave.leaves_periode_end).getTime() -
                    new Date(leave.leaves_periode_start)) /
                    (1000 * 3600 * 24) +
                  1
                }}</strong>
                hari, pada
                <strong>{{ stdDate(leave.leaves_periode_start) }}</strong>
                sampai dengan
                <strong>{{ stdDate(leave.leaves_periode_end) }}</strong> untuk
                kepentingan <strong>{{ leave.leaves_description }}</strong
                >.
              </b-card-text>
              <b-badge
                class="mb-1"
                :variant="status_kode[1][leave.sg_kode]"
              >
                {{ status_kode[0][leave.sg_kode] }}
              </b-badge>
              <b-card-text class="font-italic">
                Diperiksa pada {{ longDate(leave.updated_at) }}
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- cuti sidebar -->
    <add-cuti-sidebar />
    <!-- update modal -->
    <b-modal
      ref="UpdateCuti"
      title="Ubah Data Cuti"
      ok-title="Simpan"
      cancel-variant="outline-secondary"
      hide-footer
      centered
    >
      <b-form @submit.prevent="SaveEditLeaves()">
        <b-form-group label="Tanggal Mulai Cuti">
          <flat-pickr v-model="leave_date_start" class="form-control" />
        </b-form-group>
        <b-form-group label="Tanggal Akhir Cuti">
          <flat-pickr v-model="leave_date_to" class="form-control" />
        </b-form-group>
        <b-form-group label="Jenis Voucher" label-for="voucher-tipe">
          <v-select
            id="voucher-tipe"
            v-model="voucher"
            label="voucher_name"
            :options="voucherReff"
            class="mb-2"
          />
        </b-form-group>
        <b-form-group label="Alasan Cuti" label-for="alasan-cuti">
          <b-form-textarea
            id="alasan-cuti"
            v-model="leave_description"
            rows="3"
            class="mb-2"
          />
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            block
          >
            <b-spinner
              v-show="isSpinner"
              class="ml-auto"
              :hidden="!isSpinner"
              small
              type="grow"
            />
            <div :hidden="isSpinner">
              <span class="align-middle">Simpan</span>
            </div>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="resetForm()"
          >
            Batal
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-lg"
      ref="voucherRemaining"
      ok-only
      ok-title="Accept"
      hide-footer
      centered
      size="lg"
      title="Voucher Cuti"
    >
      <b-row>
        <b-col md="12">
          <b-card no-body class="card-statistics">
            <b-card-body class="statistics-body">
              <b-row>
                <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="info"
                      >
                        <feather-icon
                          size="24"
                          icon="SmileIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ vStats.voucher_total }} Voucher
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Total
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="success"
                      >
                        <feather-icon
                          size="24"
                          icon="CheckCircleIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ vStats.voucher_available }} Voucher
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Tersedia
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="danger"
                      >
                        <feather-icon
                          size="24"
                          icon="XCircleIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ vStats.voucher_taken }} Voucher
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Sudah Digunakan
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="6" sm="12" v-for="(remain, index) in vRemaining" :key="index">
          <b-card 
            no-body
          >
            <b-card-header class="align-items-baseline" >
              <div>
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="danger"
                        v-if="remain.claim_status == 1"
                      >
                        <feather-icon
                          size="24"
                          icon="XCircleIcon"
                        />
                      </b-avatar>
                      <b-avatar
                        size="48"
                        variant="success"
                        v-else
                      >
                        <feather-icon
                          size="24"
                          icon="CheckCircleIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-card-title class="mb-1">{{remain.claim_code}}</b-card-title>
                      <b-card-sub-title class="mb-0 font-italic" v-if="remain.claim_status == 1">
                      Diambil pada {{longDate(remain.claim_date)}}
                      </b-card-sub-title>
                      <b-card-sub-title class="mb-0 font-italic" v-else>
                      Belum Diambil 
                      </b-card-sub-title>
                    </b-media-body>
                  </b-media>

              </div>
            </b-card-header>
          </b-card>  
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BImg,
  BSpinner,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BForm,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BFormTextarea,
  BLink,BMedia, BMediaAside, BAvatar, BMediaBody
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "axios";
import AddCutiSidebar from "./AddCutiSidebar.vue";
import ModalUpdate from "./ModalUpdate.vue";
import moment from "moment";
import "moment/locale/id";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BImg,
    BSpinner,
    BOverlay,
    BForm,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormTextarea,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddCutiSidebar,
    ModalUpdate,
    flatPickr,
    BLink,BMedia, BMediaAside, BAvatar, BMediaBody
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isOverlay: false,
      isNull: false,
      isTableShow: false,
      isSpinner: false,
      isLoadingHideButton: true,
      isDisableByVerified: true,
      status: [],
      year_periode: [],
      month_periode: [],
      statusReff: [],
      monthPeriode: [
        {
          monthCode: "01",
          monthName: "Januari",
        },
        {
          monthCode: "02",
          monthName: "Februari",
        },
        {
          monthCode: "03",
          monthName: "Maret",
        },
        {
          monthCode: "04",
          monthName: "April",
        },
        {
          monthCode: "05",
          monthName: "Mei",
        },
        {
          monthCode: "06",
          monthName: "Juni",
        },
        {
          monthCode: "07",
          monthName: "Juli",
        },
        {
          monthCode: "08",
          monthName: "Agustus",
        },
        {
          monthCode: "09",
          monthName: "September",
        },
        {
          monthCode: "10",
          monthName: "Oktober",
        },
        {
          monthCode: "11",
          monthName: "November",
        },
        {
          monthCode: "12",
          monthName: "Desember",
        },
      ],
      yearPeriode: [
        {
          yearCode: "2022",
          year: "2022",
        },
        {
          yearCode: "2023",
          year: "2023",
        },
        {
          yearCode: "2024",
          year: "2024",
        },
        {
          yearCode: "2025",
          year: "2025",
        },
        // {
        //   yearCode: "2026",
        //   year: "2026",
        // },
        // {
        //   yearCode: "2027",
        //   year: "2027",
        // },
        // {
        //   yearCode: "2028",
        //   year: "2028",
        // },
        // {
        //   yearCode: "2029",
        //   year: "2029",
        // },
        // {
        //   yearCode: "2030",
        //   year: "2030",
        // },
      ],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      leaves: [],
      status_kode: [
        {
          "LVS-00": "OPEN",
          "LVS-01": "PROPOSED",
          "LVS-02": "APPROVED",
          "LVS-03": "CANCELED",
          "LVS-04": "REJECTED",
          "LVS-05": "VERIFIED",
        },
        {
          "LVS-00": "light-primary",
          "LVS-01": "light-warning",
          "LVS-02": "light-info",
          "LVS-03": "light-secondary",
          "LVS-04": "light-danger",
          "LVS-05": "success",
        },
      ],
      selected: [],
      LeavesCode: [],
      uLeave: [],
      voucherReff: [],
      leave_date_start: [],
      leave_date_to: [],
      leave_description: null,
      voucher: null,
      voucher_desc:[{
        '1': 'Cuti Tahunan', '3': 'Cuti Khusus', '4': 'Cuti Diluar Tanggungan', '5': 'Cuti Panjang', '7': 'Cuti Pelatihan / Pendidikan'
      }],
      vRemaining: [],
      vStats:[],
    };
  },
  created() {
    this.getLeaveEmployee();
    this.getVoucherReff()
    this.getLeavesStatusReff();
  },
  mounted(){
    this.intervalFetchData();
  },
  methods: {
    timeAgo(date) {
      moment.locale("id");

      return moment(date).fromNow();
    },
    longDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM  YYYY h:mm a");
    },
    stdDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM YYYY");
    },
    getVoucherReff() {
      axios.get('/leaves/voucherReff').then(response => {
        // console.log(response.data.data)
        this.voucherReff = response.data.data
      })
    },
    resetFilter() {
      this.status = [];
      this.month_periode = [];
      this.year_periode = [];
    },
    resetSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getLeavesStatusReff() {
      axios.get("/leaves/statusReff").then((response) => {
        // console.log(response.data);
        this.status = response.data.data;
      });
    },
    intervalFetchData() {
      setInterval(() => {
        this.fetchDataList();
      }, 300000);
    },
    voucherRemaining(){
      this.$refs["voucherRemaining"].show();
      axios.post("/employee/myLeaveVoucher",{
        employee_code: this.employeeData.employee_code,
      }).then((response) => {
        // console.log(response);
        this.vRemaining = response.data.data.voucher_list
        this.vStats = response.data.data
      })
    },
    fetchDataList() {
      axios
        .post("leaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((res) => {
          this.leaves = res.data.data;
        });
    },
    getLeaveEmployee() {
      (this.isOverlay = true), (this.isLoadingHideButton = false);
      axios
        .post("leaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          //this.ToastDataFound()
          // console.log(response);
          this.leaves = response.data.data;
          this.isTableShow = true;
          this.isNull = false;
        })
        .catch((error) => {
          // console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.ToastDataNotFound();
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          (this.isOverlay = false), (this.isLoadingHideButton = true);
        });
    },
    searchLeaveEmployee() {
      (this.isOverlay = true), (this.isLoadingHideButton = false);
      axios
        .post("leaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          this.ToastDataFound()
          //console.log(response);
          this.leaves = response.data.data;
          this.isTableShow = true;
          this.isNull = false;
        })
        .catch((error) => {
          //console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.ToastDataNotFound();
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          (this.isOverlay = false), (this.isLoadingHideButton = true);
        });
    },
    onClickUpdate(leave) {
      this.uLeave = leave.leaves_code;
      //console.log(this.uLeave);
      this.$refs["UpdateCuti"].show();
      if(this.uLeave !== null) {
        axios.post('leaves/detil', {
        leave_code: this.uLeave
        })
        .then(response => {
          //console.log(response);
          this.leave_date_start = response.data.data.leaves_periode_start
          this.leave_date_to = response.data.data.leaves_periode_end
          this.leave_description = response.data.data.leaves_description
          this.voucher = response.data.data.voucher
          this.detil = response.data
          // this.leave_date_start = response.data.data.leaves_periode_start
        })
      } else {
        console.log(error);
      }
    },
    SaveEditLeaves() {
      //console.log(this.uLeave);
      this.isOverlay = true
      axios.patch('/leaves/update', {
        employee_code: this.employeeData.employee_code,
        leave_code: this.uLeave,
        leave_date_start: this.leave_date_start,
        leave_date_to: this.leave_date_to,
        voucher_id: this.voucher.voucher_id,
        leave_description: this.leave_description,
      })
        .then(response => {
          //this.$router.push({ name: "cuti" }).catch(() => {});
          console.log(response)
          this.$swal({
            icon: 'success',
            title: 'Data berhasil disimpan',
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
          }).then(
            () => {},
            // handling the promise rejection
            dismiss => {
              if (dismiss === 'timer') {
                // console.log('I was closed by the timer')
              }
            },
          )
        // }
        })
        .catch(error => {
          //console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error.response.data.message)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error.response)
          }
        })
        .finally(() => {
          this.isOverlay = false
          this.$refs["UpdateCuti"].hide();
          this.getLeaveEmployee();
          // this.$router.push({ name: 'cuti' }).catch(() => {})
          // this.$refs['sidebar-right-update'].hide()
        })
    },
    confirmText(leave) {
      this.uLeave = leave.leaves_code;
      this.$swal({
        title: "Apa anda yakin?",
        text: "Cuti yang sudah dibatalkan tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isOverlay = true
          axios
            .patch("/leaves/cancel", {
              leave_code: this.uLeave,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Canceled!",
                text: "Cuti anda berhasil dibatalkan.",
                timer: 1500,
              });
            })
            .catch((error) => {
              console.log(error.response.data.message);
            })
            .finally(() => {
              this.getLeaveEmployee();
              this.isOverlay = true
            });
        }
      });
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 99;

  // opacity: 0;
  // // transform: translateY(100px);
  // transition: all .5s ease;

  // &.show {
  //   opacity: 1;
  //   // transform: translateY(0)
  // }
}
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
